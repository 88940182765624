import React from 'react'
import {Link} from 'gatsby'

import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'
import Title from '../components/title';

import {withStyles} from '@material-ui/core/styles';

import img1 from '../images/przewoz-zwierzat/1.jpg';
import img2 from '../images/przewoz-zwierzat/2.jpg';
import img3 from '../images/przewoz-zwierzat/3.jpg';

const styles = {
  header: {fontSize: 24, marginBottom: "0.8em"},
  paragraph: {marginBottom: "2em"},
  text: {fontSize: "12px", textAlign: "justify"},
  li: {margin: "auto"}
};

const Paczki = () => (
  <Layout>
    <SEO title="Przewóz zwierząt"
         keywords={[`Paczki`, `Anglia`, `Paczki Polska Anglia`, `Paczki Anglia`, `UK-TRANS`, `Przewozy Okonek`, `Przewozy-Okonek`, `Przewozy.okonek`, `Przeprowadzki anglia.`]}/>
    <Title>PRZEWÓZ ZWIERZĄT POLSKA ANGLIA</Title>
    <div className="container" css={styles.text}>
      <p>Przewóz zwierząt</p>

      <p>Nasza firma obok przewozu osób i paczek, zajmuje się także przewozem zwierząt domowych między innymi : psów i
        kotów z Polski do Anglii i z Anglii do Polski.
        W zależności od potrzeb naszych klientów termin i cenę przewozu zwierzaka ustalamy indywidualnie.</p>

      <p>UWAGA!</p>

      <p><strong>Prosimy o zapoznanie się z przepisami danego kraju dotyczącymi przewozu zwierząt.</strong></p>

      <strong>Warunki, które muszą być spełnione :</strong>
      <ul>
        <li css={styles.li}>Przewożone zwierzęta muszą posiadać książeczki szczepień (przeciwko wściekliźnielub nosówce) oraz aktualne
          zaświadczenia weterynaryjne o stanie zdrowia uwzględniające przepisy kraju wylotu, docelowego oraz portów
          tranzytowych
        </li>
        <li css={styles.li}>Wymagany jest specjalny paszport (wydawany przez weterynarza) z opisem m.in. maści, rasy, płci zwierzęcia
          wraz z
          numerem Microchipu lub tatuażu.
        </li>
        <li css={styles.li}>Zwierzę musi mieć wszczepiony znak identyfikacyjny w postaci mikroczipa.</li>
        <li css={styles.li}>Przewóz zwierzęcia należy zgłosić z wyprzedzeniem minimalnym 48 godzin przed wyjazdem ( liczba zwierząt
          przewożonych jednocześnie jednym autem jest ograniczona)
        </li>
      </ul>

      <p>
        <strong>Zwierzęta w luku bagażowym:</strong>
      </p>

      <p>Pojemnik, w którym przewożone będzie zwierzę w luku bagażowym powinno spełniać określone wymogi :</p>
      <ul>
        <li css={styles.li}>musi być odpowiednio duży, aby zwierzę miało zapewnioną swobodę ruchu i mogło w nim stanąć , obrócić się i
          położyć, musi być czysty, szczelny i dobrze zamknięty, a jego podłoże musi być wyłożone od wewnątrz materiałem
          chłonnym,
        </li>
        <li css={styles.li}>musi być wyposażony w pojemnik z wodą i jedzeniem,</li>
        <li css={styles.li}>musi być wentylowany z co najmniej trzech stron,</li>
        <li css={styles.li}>musi być wykonany z włókna szklanego, metalu, sztywnego tworzywa sztucznego, siatki metalowej, litego drewna
          lub
          sklejki.
        </li>
      </ul>

      <p>Należy pamiętać o tym, że przy odprawie może nastąpić odmowa przewozu zwierzęcia z powodu nieważnych badań bądź
        innych czynników które mogą wpłynąć na przewóz danego zwierzaka.W celu określenia dokładnej ceny przejazdu osób
        i
        zwierząt prosimy o kontakt meilowy poprzez zakładkę kontakt bądź telefoniczny.</p>

      <p>TEL. PL +48 513 900 083</p>

      <p>TEL. UK 0044 759 818 69 16</p>

      <p>TEL. UK 0044 759 904 2617</p>


      <img src={img2} srcSet={`${img2} 300, ${img3} 768w, ${img1} 945w`}/>


      <div css={styles.paragraph}>
        Jesteśmy jednym z nielicznych licencjonowanych przewoźników, którzy podejmują się transportu zwierząt na
        dalekich trasach. Podróż samolotem dla zwierzęcia może być traumatycznym wydarzeniem. Zmiany ciśnienia, hałas, a
        także konieczność podróży w transporterze w luku bagażowym powoduje, że właściciele zwierząt często rezygnują z
        ich przewiezienia za granicę. Na trasie Polska-Anglia możemy Wam pomóc w transporcie Waszych pupili.
      </div>
      <div css={styles.header}>Przewóz zwierząt z Polski do Anglii</div>
      <div css={styles.paragraph}>Wiemy, jak bardzo stresująca może być dla psów i kotów podróż, ale wiemy też,
        jak im tę podróż ułatwić. Przede wszystkim należy zadbać o odpowiedni transporter dla zwierzaka. Powinien być
        wentylowany co najmniej z trzech stron. W środku należy umieścić maty wchłaniające, pojemnik z suchym jedzeniem
        oraz wodę do picia, która nie będzie nadmiernie wylewać się w trakcie drogi. Pamiętajcie o niezbędnych
        dokumentach – aktualnych szczepieniach i paszportach wydawanych przez lekarza weterynarii. Bez tych dokumentów
        Twój zwierzak nie przekroczy granicy. Dlatego pamiętajcie o formalnościach. My przewieziemy szybko i bezpiecznie
        Waszego zwierzaka do wyznaczonego miejsca.
      </div>
      <div css={styles.header}>Przewóz zwierząt z Anglii do Polski</div>
      <div css={styles.paragraph}>Staramy się zapewnić Waszym pupilom jak najlepsze warunki podróży,
        zminimalizować stres poprzez wyciszenie samochodu oraz takie ustawienie transporterów, by zwierzęta wzajemnie
        się nie denerwowały. Wszystkie zwierzęta powinny być wyposażone w mikroczipy z danymi adresowymi właścicieli.
      </div>
    </div>
  </Layout>
)

export default withStyles(styles)(Paczki);